import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'optionValueForMultiInput',
  standalone: true,
})
export class OptionValueForMultiInputPipe implements PipeTransform {
  transform(option: string, filterValue: string): string {
    let baseValue = filterValue.split(',').slice(0, -1).join(',');
    baseValue.length > 0 && (baseValue += ', ');
    return baseValue + option;
  }
}
